/*
 * @Author: herro
 * @Date: 2022-08-22 14:08:16
 * @Description: 
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import { Loading, Message } from 'element-ui'
Vue.use(VueRouter)


const router = new VueRouter({
  routes,
  // mode:'history'
})

let loading = null
//路由白名单
let whiteList = ['/companyLogin', '/', '/home', '/platform_show/yuansuoxiao', '/greenHighQuality/index', '/greenHighQuality', '/greenHighQuality/policyDetail'];
let tokenWhite = ['/companyLogin', '/greenHighQuality/index', '/greenHighQuality', '/greenHighQuality/policyDetail']
router.beforeEach((to, from, next) => {
  // loading = Loading.service({
  //   lock: true,
  //   // text: '数据加载中，请稍后...',
  //   spinner: 'el-icon-loading',
  //   background: 'rgba(0,0,0,0.7)',
  // })

  if (to.path === '/login') {
    next()
  } else {
    if (tokenWhite.some(item => to.path.indexOf(item) != -1)) {
      next()
    } else {
      if (sessionStorage.getItem('TOKEN')) {
        let menu = JSON.parse(sessionStorage.getItem("MENULIST"))
        if (whiteList.indexOf(to.path) != -1) {
          next()
        } else {
          let bol = false;
          for (let i = 0; i < menu.length; i++) {
            if (to.path.indexOf(menu[i].path) != -1) {
              bol = true
              break;
            }
          }
          if (bol) {
            next()
          } else {
            Message({
              message: '你没有权限!',
              type: 'warning'
            })
          }
        }
      } else {
        console.log(from.path, 'from.path')
        if (from.path.indexOf('platform_company') != -1 || to.path.indexOf('platform_company') != -1) {

          next('/companyLogin')
        } else {
          next('/login')
        }
        // if (from.path == '/login') {
        // loading.close()
        // }
      }
    }

  }
})

router.afterEach((to, from, next) => {
  document.title = '西安高新区科创平台' + (to.meta.title ? ('-' + to.meta.title) : '')
  window.scrollTo(0, 0)
})
export default router

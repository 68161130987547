export default {
  thousandToBillion(thousand, bit = 2) {
    return Number((thousand / 100000).toFixed(bit))
  },
  toTenThousand(data, bit = 2) {
    const num = data ? +data : 0
    return Number((num / 10000).toFixed(bit))
  },
  toFixed2(val) {
    let value = Math.round(parseFloat(val) * 100) / 100
    const s = value.toString().split('.')
    if (s.length == 1) {
      value = value.toString() + '.00'
      return value
    }
    if (s.length > 1) {
      if (s[1].length < 2) {
        value = value.toString() + '0'
      }
      return value
    }
  },
}

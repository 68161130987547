var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',[_c('div',{staticClass:"date-content",style:({
      backgroundImage: `url(${
        _vm.THEME.homeNav_date_bg
      })`,
      color: `${_vm.THEME.homeNav_date_day_color}`,
    })},[_c('span',[_vm._v(_vm._s(_vm.dateContent.day))]),_c('div',{staticClass:"line",style:({
        color: _vm.THEME.homeNav_date_date_color,
      })}),_c('p',{style:({
        color: _vm.THEME.homeNav_date_date_color,
      })},[_vm._v(" "+_vm._s(_vm.dateContent.year)+"年"),_c('br'),_vm._v(_vm._s(_vm.dateContent.month)+"月 ")])]),_vm._m(0),_c('C_admin_control')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_c('img',{attrs:{"src":require("@/assets/img/login/logo.png"),"alt":"logo"}}),_c('span',[_vm._v("西安高新区科技创新平台")])])
}]

export { render, staticRenderFns }
/*
 * @Author: herro
 * @Date: 2022-08-22 14:08:16
 * @Description: main.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import all_mixin from './mixins/all_mixin'
import 'vuescroll/dist/vuescroll.css'

import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
Vue.use(preview)

import * as echarts from 'echarts'
import 'echarts-gl' // 3d图表库
///富文本编辑器样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.prototype.$echarts = echarts
import IPlugin from './plugin'
Vue.use(IPlugin) //引入自定义插件
Vue.use(ElementUI);
Vue.use(all_mixin);//全局混入
Vue.prototype.$store = store;
Vue.config.productionTip = false
import VueCountUp from 'vue-countupjs'
Vue.use(VueCountUp)
Vue.prototype.$eventBus = new Vue()

new Vue({
  router,
  store,
  mounted() {
    this.$store.commit('CHANGE_THEME', localStorage.getItem('theme') || 'dark')
  },
  render: h => h(App)
}).$mount('#app')

<template>
  <header>
    <div
      class="date-content"
      :style="{
        backgroundImage: `url(${
          THEME.homeNav_date_bg
        })`,
        color: `${THEME.homeNav_date_day_color}`,
      }"
    >
      <span>{{ dateContent.day }}</span>
      <div
        class="line"
        :style="{
          color: THEME.homeNav_date_date_color,
        }"
      ></div>
      <p
        :style="{
          color: THEME.homeNav_date_date_color,
        }"
      >
        {{ dateContent.year }}年<br />{{ dateContent.month }}月
      </p>
    </div>
    <h2>
      <img src="~@/assets/img/login/logo.png" alt="logo" />
      <span>西安高新区科技创新平台</span>
    </h2>
    <!-- 控制组件 -->
    <C_admin_control />
  </header>
</template>
<script>
import C_admin_control from "@/components/C_admin_control";
export default {
  name: "c_header",
  components: { C_admin_control },
  data() {
    return {
      dateContent: {
        year: "",
        month: "",
        day: "",
      },
      timer: null,
    };
  },
  mounted() {
    this.computeDate(new Date());
    this.timer = setInterval(() => {
      this.computeDate(new Date());
    }, 1000 * 60 * 60 * 24);
  },
  methods: {
    // 计算日期
    computeDate(date) {
      this.dateContent.year = date.getFullYear();
      this.dateContent.month = date.getMonth() + 1;
      this.dateContent.day = date.getDate();
      this.dateContent.day = this.dateContent.day>9?this.dateContent.day:('0'+this.dateContent.day)
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
};
</script>
<style lang='less' scoped>
header {
  background: url("@/assets/img/homeNav/home-head-bg.png") no-repeat top center;
  background-size: auto cover;
  //   display: flex;
  //   flex-flow: row nowrap;
  //   justify-content: space-between;
  //   align-items: center;
  position: relative;
  height: 60px;
  padding: 0 26px;

  // margin-bottom:60px;
  h2 {
    text-align: center;
    font-size: 30px;
    font-family: "fzzch";
    letter-spacing: 0.1em;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);

    background-image: -webkit-linear-gradient(top, #e3ebfe, #fefeff, #e6edfc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    img {
      margin-right: 20px;
      height: 39px;
      width: 39px;
    }
  }

  .date-content {
    background: url("@/assets/img/homeNav/date-bg.png") no-repeat;
    background-size: 100% 100%;
    width: 224px;
    height: 54px;
    position: absolute;
    left: 26px;
    top: 50%;
    transform: translateY(-50%);
    color: #a5c6ed;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 10px 0 10px 70px;
    span {
      font-size: 30px;
    }
    div {
      width: 1px;
      height: 100%;
      background-color: #a5c6ed;
      margin: 0 10px;
    }
    p {
      font-size: 12px;
    }
  }
}
</style>
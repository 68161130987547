/**
 * @description 柱状图和折线图基本配置
 * @type {Object}
 */
const BAR_OR_LINE_OPTION = {
  // color: ['#0be3f5', '#3f98fd', '#a4f68f', '#fd9d73', '#ffcb00', '#b448eb'],
  legend: { textStyle: { color: '#888', fontSize: 12 } },
  tooltip: { axisPointer: { type: 'shadow' }, trigger: 'axis' },
  xAxis: {
    type: 'category',
    axisLabel: { color: '#888', fontSize: 14 },
    axisLine: { lineStyle: { color: 'rgba(255,255,255,0.1)' } },
    axisTick: { show: false },
  },
  yAxis: {
    type: 'value',
    axisLabel: { color: '#507cf1', fontSize: 12 },
    nameTextStyle: { color: '#507cf1', fontSize: 12 },
    axisLine: { show: false },
    splitLine: { lineStyle: { color: 'rgba(255,255,255,0.1)' } },
  },
}

/**
 * @description 圆形柱状图基本配置
 * @type {Object}
 */
const CIRCLE_BAR_OPTION = {
  angleAxis: { axisLine: { show: false }, axisLabel: { show: false }, axisTick: { show: false }, splitLine: { show: false } },
  radiusAxis: { axisTick: { show: false }, axisLabel: { show: false, fontSize: 7, color: '#ccc' }, axisLine: { show: false }, type: 'category', z: 1 },
  polar: { radius: '85%' },
  tooltip: { show: true, trigger: 'axis' },
}

/**
 * @description 仪表板图基本配置
 * @type {Object}
 */
const GAUGE_OPTION = {
  series: [
    {
      radius: '85%',
      type: 'gauge',
      detail: {
        formatter: '{value}',
        fontSize: 40,
        fontWight: 'bolder',
        offsetCenter: [0, 0],
        textStyle: { color: '#fff' },
      },
      axisLine: { show: true },
      axisLabel: { show: false },
      splitLine: { show: false },
      axisTick: { show: false },
      itemStyle: { opacity: 0 },
    },
    {
      radius: '70%',
      type: 'gauge',
      detail: {
        fontSize: 20,
        fontWeight: 'bold',
        offsetCenter: [0, '45%'],
        textStyle: { color: '#71afff' },
      },
      axisLine: { show: false },
      axisLabel: { show: false },
      splitLine: { show: false },
      axisTick: { show: true, splitNumber: 10, length: 15, lineStyle: { color: 'rgba(255,255,255,0.1)' } },
      itemStyle: { opacity: 0 },
    },
    {
      radius: '70%',
      type: 'gauge',
      detail: {
        fontSize: 16,
        offsetCenter: [0, '75%'],
        textStyle: { color: '#71afff' },
      },
      axisLine: { show: false },
      axisLabel: { show: false },
      splitLine: { show: false },
      axisTick: { show: true, splitNumber: 10, length: 15, lineStyle: { color: 'rgba(255,255,255,0.1)' } },
      itemStyle: { opacity: 0 },
    },
  ],
}

/**
 * @description 饼状图基本配置
 * @type {Object}
 */
const PIE_OPTION = { tooltip: { trigger: 'item' } }

// const DOUGHNUT_OPTION = _.merge({}, PIE_OPTION)

/**
 * @description 雷达图基本配置
 * @type {Object}
 */
const RADAR_OPTION = {
  radar: {
    name: { textStyle: { color: '#55c8d4' } },
    radius: '70%',
    axisLine: { lineStyle: { color: 'rgba(255,255,255,0.1)' } },
    splitLine: { lineStyle: { color: 'rgba(255,255,255,0.1)' } },
    splitArea: { show: false },
  },
}

/**
 * @description 柱状图series基本配置
 * @type {Object}
 */
const BAR = { type: 'bar', barWidth: 15 }

/**
 * @description 柱状图series圆角配置
 * @type {Object}
 */
const RADIUS_BAR = _.merge({}, BAR, { itemStyle: { barBorderRadius: Number.MAX_SAFE_INTEGER } })

/**
 * @description 折线图series基本配置
 * @type {Object}
 */
const LINE = { type: 'line', lineStyle: { width: 2 }, symbol: 'none' }

/**
 * @description 圆形柱状图series基本配置
 * @type {Object}
 */
const CIRCLE_BAR = _.merge({}, BAR, { barWidth: '65%', coordinateSystem: 'polar' })

/**
 * @description 饼状图series基本配置
 * @type {Object}
 */
const PIE = { type: 'pie', center: ['50%', '50%'] }

/**
 * @description 甜甜圈饼状图series基本配置
 * @type {Object}
 */
const DOUGHNUT_PIE = _.merge({ radius: ['50%', '70%'], labelLine: { show: false } }, PIE)

/**
 * @description 玫瑰饼状图series基本配置
 * @type {Object}
 */
const ROSE_PIE = _.merge({ radius: ['20%', '70%'], roseType: 'radius' }, PIE)

export default {
  /**
   * @author jiaojiannan
   * @description 用来渲染柱状图
   * @param {Echarts} instance
   * @param {Object} option 包括两个自定义属性，$series用来传入数据（必传项），$barRadius设定柱形是否为圆角（可以省略）
   */
  bar(instance, option) {
    //生成series
    option.series = []
    for (const item of option.$series) {
      option.series.push(_.merge({}, option.$barRadius ? RADIUS_BAR : BAR, item))
    }

    //删除自定义的属性
    delete option.$series
    delete option.$barRadius

    instance.setOption(_.merge({}, BAR_OR_LINE_OPTION, option), true)
  },

  /**
   * @author jiaojiannan
   * @description 用来渲染柱状加折线图
   * @param {Echarts} instance
   * @param {Object} option 包括两个自定义属性，$data用来传入数据（必传项），$barRadius设定柱形是否为圆角（可以省略）
   */
  barAndLine(instance, option) {
    //生成series
    option.series = []
    for (const item of option.$data) {
      if (item.type && 'line' === item.type) option.series.push(_.merge({}, LINE, item))
      else option.series.push(_.merge({}, option.$barRadius ? RADIUS_BAR : BAR, item))
    }

    //删除自定义的属性
    delete option.$data
    delete option.$barRadius

    instance.setOption(_.merge({}, BAR_OR_LINE_OPTION, option), true)
  },

  /**
   * @author jiaojiannan
   * @description 用来渲染环形的柱状图
   * @param {Echarts} instance
   * @param {Object} option 包括两个自定义属性，$data用来传入数据（必传项），$barColor用于指定柱形颜色（必传）
   */
  circleBar(instance, option) {
    const barColor = _.cloneDeep(option.$barColor)

    //生成series
    option.series = []
    for (const item of option.$data) {
      option.series.push(
        _.merge(
          {
            itemStyle: {
              color: function(arg) {
                if (arg.name === '') return 'rgba(0,0,0,0)'
                else return barColor[arg.name]
              },
            },
          },
          CIRCLE_BAR,
          item
        )
      )
    }

    //删除自定义的属性
    delete option.$data
    delete option.$barColor

    instance.setOption(_.merge({}, CIRCLE_BAR_OPTION, option), true)
  },

  doughnut(instance, option) {
    //生成series
    option.series = _.merge({}, DOUGHNUT_PIE, option.$series)

    //删除自定义的属性
    delete option.$series

    instance.setOption(_.merge({}, PIE_OPTION, option), true)
  },

  /**
   * @author jiaojiannan
   * @description 用来渲染仪表板图
   * @param {Echarts} instance
   * @param {Object} option
   */
  gauge(instance, option) {
    const _option = _.merge({}, GAUGE_OPTION)

    _option.series[0].data = [option.data]
    _option.series[0].axisLine.lineStyle = {
      width: 20,
      color: [
        [
          option.progress,
          new echarts.graphic.LinearGradient(1, 0, 0, 0, [
            { offset: 0, color: option.circleColor[0] },
            { offset: 1, color: option.circleColor[1] },
          ]),
        ],
        [1, 'rgba(255,255,255,0.06)'],
      ],
    }

    _option.series[1].detail.formatter = option.title
    _option.series[2].detail.formatter = option.subtitle

    instance.setOption(_option, true)
  },

  /**
   * @author jiaojiannan
   * @description 用来渲染折线图
   * @param {Echarts} instance
   * @param {Object} option 包括两个自定义属性，$data用来传入数据（必传项），$areaFill设定折线图是否开启区域填充（可以省略）
   */
  line(instance, option) {
    //生成series
    option.series = []
    if (option.$areaFill)
      for (let i = 0, len = option.$data.length; i < len; i++) {
        const item = option.$data[i]
        option.series.push(
          _.merge(
            {
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: colorRgba(option.color[i], 0.6) },
                  { offset: 0.5, color: colorRgba(option.color[i], 0.4) },
                  { offset: 1, color: colorRgba(option.color[i], 0) },
                ]),
              },
            },
            LINE,
            item
          )
        )
      }
    else
      for (const item of option.$data) {
        option.series.push(_.merge({}, LINE, item))
      }

    //删除自定义的属性
    delete option.$data
    delete option.$areaFill

    instance.setOption(_.merge({}, BAR_OR_LINE_OPTION, option), true)
  },

  /**
   * @author jiaojiannan
   * @description 用来渲染雷达图
   * @param {Echarts} instance
   * @param {Object} option
   */
  radar(instance, option) {
    instance.setOption(_.merge({}, RADAR_OPTION, option), true)
  },

  /**
   * @author jiaojiannan
   * @description 用来渲染玫瑰饼状图
   * @param {Echarts} instance
   * @param {Object} option 包括自定义属性$series用来传入数据（必传项）及修改series设置
   */
  rosePie(instance, option) {
    //生成series
    option.series = []
    for (const item of option.$series) {
      option.series.push(_.merge({}, ROSE_PIE, item))
    }

    //删除自定义的属性
    delete option.$series

    instance.setOption(_.merge({}, PIE_OPTION, option), true)
  },

  stackBar(instance, option) {
    //生成series
    option.series = []
    if (option.$barRadius)
      for (let i = 0, len = option.$data.length; i < len; i++) {
        const item = option.$data[i]
        switch (i) {
          case 0:
            option.series.push(_.merge({ itemStyle: { barBorderRadius: [0, 0, Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER] } }, BAR, item))
            break
          case len - 1:
            option.series.push(_.merge({ itemStyle: { barBorderRadius: [Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER, 0, 0] } }, BAR, item))
            break
          default:
            option.series.push(_.merge({}, BAR, item))
            break
        }
      }
    else
      for (const item of option.$data) {
        option.series.push(_.merge({}, BAR, item))
      }

    //删除自定义的属性
    delete option.$data
    delete option.$barRadius

    instance.setOption(_.merge({}, BAR_OR_LINE_OPTION, option), true)
  },

  colorRgba,
}

function colorRgba(originColor, opacity) {
  let sColor = originColor.toLowerCase()
  //十六进制颜色值的正则表达式
  const reg = /^#([0-9a-f]{3}|[0-9a-f]{6})$/
  // 如果是16进制颜色
  if (sColor && reg.test(sColor)) {
    if (sColor.length === 4) {
      let sColorNew = '#'
      for (let i = 1; i < 4; i += 1) {
        sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1))
      }
      sColor = sColorNew
    }
    //处理六位的颜色值
    const sColorChange = []
    for (let i = 1; i < 7; i += 2) {
      sColorChange.push(parseInt('0x' + sColor.slice(i, i + 2)))
    }
    return `rgba(${sColorChange.join(',')},${opacity})`
  }
  return sColor
}

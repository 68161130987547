<template>
  <div class="homeNav" :style="{ backgroundImage: `url(${THEME.homeNav_bg})` }">
    <!-- 头部信息 -->
    <c_header />
    <!-- 主体部分 -->
    <div class="content">
      <!-- 大图展示 -->
      <div
        class="advertisement"
        :style="{
          backgroundImage: `url(${THEME.homeNav_ad_bg})`,
          color: THEME.homeNav_date_date_color,
        }"
      >
        <div class="box">
          <span>世界一流<br />科技园区</span>
          <img src="~@/assets/img/homeNav/box-bg.png" alt="" />
        </div>
        <img src="~@/assets/img/homeNav/division.png" alt="" />
        <div class="box">
          <span>国家自主创新<br />示范区</span>
          <img src="~@/assets/img/homeNav/box-bg.png" alt="" />
        </div>
        <img src="~@/assets/img/homeNav/division.png" alt="" />
        <div class="box">
          <span>硬科技创新<br />示范区</span>
          <img src="~@/assets/img/homeNav/box-bg.png" alt="" />
        </div>
        <!-- <img src="~@/assets/img/homeNav/division.png" alt="" />
        <div class="box">
          <span>"秦创原"使命践行区、<br />核心引擎区、示范样板区</span>
          <img src="~@/assets/img/homeNav/box-bg.png" alt="" />
        </div> -->
      </div>

      <!-- 导航nav -->
      <div class="nav">
        <div class="nav-item" @click="routerJump('/platform_show')">
          <section>
            <h4 :style="{ color: THEME.homeNav_date_day_color }">
              科创展示平台
            </h4>
            <p :style="{ color: THEME.homeNav_date_date_color }">
              科创企业、创新平台、科技成果转化、创新要素、科创项目等相关数据展示平台
            </p>
          </section>
          <img src="~@/assets/img/homeNav/center1.png" alt="" />
        </div>
        <div class="nav-item" @click="routerJump('/platform_main')">
          <section>
            <h4 :style="{ color: THEME.homeNav_date_day_color }">
              科创主体平台
            </h4>
            <p :style="{ color: THEME.homeNav_date_date_color }">
              科创企业、科创载体、企业上市、科创项目等主体指标分析工作平台
            </p>
          </section>
          <img src="~@/assets/img/homeNav/center2.png" alt="" />
        </div>
        <div class="nav-item" @click="routerJump('/platform_analysis')">
          <section>
            <h4 :style="{ color: THEME.homeNav_date_day_color }">
              科创分析平台
            </h4>
            <p :style="{ color: THEME.homeNav_date_date_color }">
              选指综合分析、科技创新报告综合分析平台
            </p>
          </section>
          <img src="~@/assets/img/homeNav/center3.png" alt="" />
        </div>
        <div class="nav-item" @click="routerJump('/platform_spaceTime')">
          <img src="~@/assets/img/homeNav/center4.png" alt="" />
          <section>
            <h4 :style="{ color: THEME.homeNav_date_day_color }">
              科创时空平台
            </h4>
            <p :style="{ color: THEME.homeNav_date_date_color }">
              数据展示服务、数据分析服务、时空信息服务
            </p>
          </section>
        </div>
        <div class="nav-item" @click="routerJump('/platform_factor')">
          <img src="~@/assets/img/homeNav/center5.png" alt="" />
          <section>
            <h4 :style="{ color: THEME.homeNav_date_day_color }">
              科创要素平台
            </h4>
            <p :style="{ color: THEME.homeNav_date_date_color }">
              科创政策、科技成果、科创金融、体制机制等要素指标分析工作平台
            </p>
          </section>
        </div>
        <div class="nav-item" @click="routerJump('/platform_work')">
          <img src="~@/assets/img/homeNav/center6.png" alt="" />
          <section>
            <h4 :style="{ color: THEME.homeNav_date_day_color }">
              科创工作平台
            </h4>
            <p :style="{ color: THEME.homeNav_date_date_color }">
              素材编辑、素材录入、数据录入等相关工作平台
            </p>
          </section>
        </div>

        <!-- 动态展示 -->
        <img
          class="content-center"
          src="~@/assets/img/homeNav/content-center.png"
          alt=""
        />
        <img class="content-center-bg" :src="THEME.homeNav_content_bg" alt="" />
      </div>

      <!-- footerNav -->
      <div class="footer-nav">
        <template v-for="(item, index) in footnav">
          <section :key="index">
            <div
              class="item"
              v-for="item2 in item"
              :key="item2.img"
              @click="$router.push(item2.path)"
            >
              <img :src="item2.img" alt="" />
              <span :style="{ color: THEME.homeNav_date_date_color }">{{
                item2.title
              }}</span>
            </div>
          </section>
          <img
            src="~@/assets/img/homeNav/foot-line.png"
            alt=""
            v-if="index != footnav.length - 1"
            :key="index + 5"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import c_header from "./components/c_header.vue";
export default {
  name: "homeNav",
  components: { c_header },
  data() {
    return {
      footnav: [
        [
          {
            img: require("@/assets/img/homeNav/foot1.png"),
            title: "科创地图",
            path: "/platform_spaceTime",
          },
          {
            img: require("@/assets/img/homeNav/foot6.png"),
            title: "企业上市",
            path: "/platform_main/Listing/survey_index",
          },
        ],
        [
          {
            img: require("@/assets/img/homeNav/foot2.png"),
            title: "科创企业",
            path: "/platform_main/enterprise/survey_index",
          },
          {
            img: require("@/assets/img/homeNav/foot7.png"),
            title: "科创政策",
            path: "/platform_factor/policy",
          },
        ],
        [
          {
            img: require("@/assets/img/homeNav/foot3.png"),
            title: "科创载体",
            path: "/platform_main/carrier/survey_carrier",
          },
          {
            img: require("@/assets/img/homeNav/foot8.png"),
            title: "科创项目",
            path: "/platform_main/program/survey_program",
          },
        ],
        [
          {
            img: require("@/assets/img/homeNav/foot4.png"),
            title: "体制机制",
            path: "/platform_factor/mechanism",
          },
          {
            img: require("@/assets/img/homeNav/foot9.png"),
            title: "综合分析",
            path: "/platform_analysis/index_analysis",
          },
        ],
        [
          {
            img: require("@/assets/img/homeNav/foot5.png"),
            title: "科创金融",
            path: "/platform_factor/finance",
          },
          {
            img: require("@/assets/img/homeNav/foot10.png"),
            title: "工作管理",
            path: "/platform_work",
          },
        ],
      ],
    };
  },
  methods: {
    routerJump(path) {
      this.$router.push({
        path,
      });
    },
  },
};
</script>

<style lang='less' scoped>
@import "./index.less";
</style>
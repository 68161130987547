<template>
  <div
    class="admin-control"
    :style="{
      backgroundColor: THEME.homeNav_admin_bgcolor,
      color: THEME.homeNav_date_date_color,
    }"
  >
    <el-popover
      placement="bottom"
      width="160"
      trigger="click"
      style="padding: 0"
      :popper-class="'change-skin ' + $store.state.app.theme"
    >
      <div class="admin" slot="reference">
        <img :src="THEME.homeNav_admin_bg" alt="" />
        <p>{{ name }}</p>
      </div>
      <div :class="'list ' + $store.state.app.theme">
        <p @click="backLogin">切换账号</p>
        <p @click="backLogin">退出登录</p>
      </div>
    </el-popover>

    <!-- <div class="weather">
      <i :class="'qi-' + weather.img" style="width: 20px; height: 20px"></i>
      <span>{{ weather.temp }}℃</span>
      <span>{{ weather.local }}</span>
    </div> -->
    <el-popover
      placement="bottom"
      width="160"
      trigger="click"
      style="padding: 0"
      :popper-class="'change-skin ' + $store.state.app.theme"
      offset="0"
    >
      <img
        slot="reference"
        class="skin"
        src="@/assets/img/homeNav/skin.png"
        alt="换肤"
        title="换肤"
      />
      <!-- <div :class="'list ' + $store.state.app.theme">
        <p @click="changeSkin('dark')">深色主题</p>
        <p @click="changeSkin('light')">浅色主题</p>
      </div> -->
    </el-popover>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "C_admin_control",
  data() {
    return {
      weather: {
        local: "西安",
        img: "",
        temp: "--",
      },
      timer2: null,
    };
  },
  computed: {
    name() {
      return sessionStorage.getItem("USER")
        ? JSON.parse(sessionStorage.getItem("USER")).realname
        : "登录";
    },
  },
  mounted() {
    // this.getWeather();
    // this.timer2 = setInterval(() => {
    //   this.getWeather();
    // }, 1000 * 60 * 30);
  },
  methods: {
    //获取天气
    async getWeather() {
      let city = "西安";
      //高德api获取定位城市
      let iplocal = await this.$http.get(
        `https://restapi.amap.com/v3/ip?key=086bed50d14a07bb7036ceea0860c3cd`
      );
      if (iplocal.infocode == 10000) {
        city = iplocal.city;
      }
      //获取城市id
      let local = await this.$http.get(
        `https://geoapi.qweather.com/v2/city/lookup?location=${city}&key=4edc3bb5536b44de89694bb36a4929a6&adm=${city}`
      );
      if (local.code == 200) {
        let id = local.location[0].id;
        //获取天气
        let obj = await this.$http.get(
          "https://devapi.qweather.com/v7/weather/now?key=4edc3bb5536b44de89694bb36a4929a6&location=" +
            id
        );
        if (obj.code == 200) {
          let { icon: img, temp } = obj.now;
          this.weather.img = img;
          this.weather.temp = temp;
        }
      }
    },

    changeSkin(theme) {
      this.$store.commit("CHANGE_THEME", theme);
    },
    backLogin() {
      this.$store.commit("BACKLOGIN");
    },
  },
  beforeDestroy() {
    clearInterval(this.timer2);
    this.timer2 = null;
  },
};
</script>
<style lang='less' scoped>
.admin-control {
  position: absolute;
  right: 26px;
  top: 50%;
  transform: translateY(-50%);
  color: #a5c6ed;
  width: 280px;
  height: 54px;
  background-color: rgba(10, 44, 79, 0.8);
  border-radius: 27px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-around;
  .admin {
    // width: 160px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    cursor: pointer;
    img {
      width: 26px;
      height: 26px;
      margin-right: 6px;
    }
    p {
      font-size: 12px;
      max-width: 60px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .weather {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    border-left: 1px solid #1d4672;
    padding-left: 10px;
    span:nth-of-type(1) {
      margin: 0 10px;
    }
  }
  .skin {
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-top: 6px;
  }
}
.list {
  border-radius: 10px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  p {
    height: 50px;
    line-height: 50px;
    width: 100%;
    text-align: center;
    color: #6991b9;
    border-bottom: 1px solid #173b62;
    cursor: pointer;
  }
  p:hover {
    color: #1e83ea;
  }
  p:last-child {
    border: none;
  }
}
.list.light {
  p {
    color: #000 !important;
    background-color: #f0f7ff !important;
    border-bottom: 1px solid #d9e4f1 !important;
    border-radius: 10px;
  }
  p:hover {
    color: #1e83ea !important;
  }
  p:last-child {
    border: none !important;
    border-radius: 0 0 10px 10px;
  }
  p:nth-of-type(1) {
    border-radius: 10px 10px 0 0 !important;
  }
}
</style>

<style>
.el-popover.change-skin {
  border: none;
  top: 50px !important;
  padding: 0 !important;
  background-color: rgb(4, 33, 64) !important;
  border-radius: 10px;
}
.el-popover.change-skin.light {
  border: none;
  top: 50px !important;
  padding: 0 !important;
  background-color: #f0f7ff;
  box-shadow: none;
}
.el-popover.change-skin .popper__arrow {
  display: none;
}
</style>
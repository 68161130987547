/*
 * @Author: herro
 * @Date: 2022-10-24 10:58:40
 * @Description: 登录模块vuex
 */
import router from '../../router/index.js'
export default {
  state: {
    token: sessionStorage.getItem('TOKEN'),
    user: sessionStorage.getItem('USER'),
    menuList: sessionStorage.getItem('MENULIST'),
  },
  mutations: {
    BACKLOGIN(state, val) {
      sessionStorage.clear();
      router.push('login')
    }
    // CHANGE_THEME(state, val) {
    //   localStorage.setItem('theme', val)
    //   state.theme = val
    // },
    // SET_AUTO_GEO(state, val) {
    //   state.geoBoundary = val
    // },
    // SET_AUTO_RADIUS(state, val) {
    //   state.radius = val
    // }
  }
}
/*
 * @Author: herro
 * @Date: 2022-08-22 14:24:27
 * @Description: 
 */
import axios from 'axios'
import qs from 'qs'
import { Message, Loading } from 'element-ui'
import Vue from 'vue'
import router from '@/router'
export const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 100000
})
export const config = {
    system_baseUrl: '/technological',
    base_url: '/technological/api',
    main_url: '/technological/api/subject',
    factor_url: '/technological/api/finance',
    program_url: '/technological/api/project',
    carrier_url: '/technological/api/carrier'
}

const loadings = []
//设置post请求头  json类型接受
service.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'
//清除loading
function hideLoading() {
    if (!_.isEmpty(loadings)) {
        for (let i = loadings.length - 1; i >= 0; i--) {
            const load = loadings.pop()
            load.close()
        }
    }
}

//请求拦截器
service.interceptors.request.use(config => {
    ///和风天气的接口不加token
    let regx = /restapi|qweather/
    if (!regx.test(config.url)) {
        config.headers['X-Access-Token'] = sessionStorage.getItem('TOKEN')
    }
    const loading = Loading.service({
        fullscreen: true,
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
    })
    /* 防止loading意外的指向新的Loading实例而最终无法关闭 */
    let flag = false
    for (const load of loadings) {
        if (Object.is(load, loading)) {
            flag = true
            break
        }
    }

    if (!flag) {
        loadings.push(loading)
    }

    function rightParams(obj) {
        let rightData = {}
        Object.keys(obj).forEach(item => {
            if (obj[item] !== null && obj[item] !== '' && obj[item] !== undefined) {
                rightData[item] = obj[item]
            }
        })
        return rightData
    }
    if (('get' === config.method || 'delete' === config.method) && config.data) config.url = config.url + '?' + qs.stringify(rightParams(config.data))
    return config
}, error => {
    hideLoading()
    Message({
        showClose: true,
        message: '请求错误!',
        type: 'error',
    })
    return Promise.reject(error)
})

//相应拦截器
service.interceptors.response.use(res => {
    hideLoading()
    // console.log(res, typeof res.data, 'res')

    if (!(res.data.success || res.data.status == '1' || res.data.code == '200' || typeof res.data == 'string' || res.headers['content-type'] ==
        "application/force-download")) {
        Message({
            showClose: true,
            message: res.data.message,
            type: 'error',
        })
        if (res.data && !res.data.code) return

    } else if (res.data.code == '500' || res.data.status == '500') {
        if (res.data.message.indexOf('Token失效') != -1) {
            Message({
                showClose: true,
                message: 'token失效，请重新登录!',
                type: 'error',
            })
            router.push('/login')
        }
    }
    return res.data
}, error => {
    hideLoading()
    Message({
        showClose: true,
        message: error,
        type: 'error',
    })
    if (error.response.status == 500 && error.response.data.message.indexOf('Token失效') != -1) {
        Message({
            showClose: true,
            message: 'token失效，请重新登录!',
            type: 'error',
        })
        router.push('/login')
    }
    return Promise.reject(error)
})
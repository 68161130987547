/*
 * @Author: herro
 * @Date: 2022-08-25 08:26:58
 * @Description: 
 */
export default {
    dark: {
        homeNav_bg: require('@/assets/img/homeNav/home-bg.jpg'),
        homeNav_date_bg: require('@/assets/img/homeNav/date-bg.png'),
        homeNav_date_day_color: '#a5c6ed',
        homeNav_date_date_color: '#a5c6ed',
        homeNav_admin_bg: require('@/assets/img/homeNav/admin-bg.png'),
        homeNav_admin_bgcolor: '#042140',
        homeNav_ad_bg: require('@/assets/img/homeNav/ad-bg.png'),
        homeNav_content_bg: require('@/assets/img/homeNav/content-center-bg.png'),
        spaceTime_mapStyle: 'c0f77d272e945e3ff6c5a268cfe362ee',

        tooltip: {///echarts提示框样式
            backgroundColor: "rgba(31,44,66,0.9)", //设置背景图片 rgba格式
            borderColor: 'transparent',
            textStyle: {
                color: '#92add7'
            }
        }
    },
    light: {
        homeNav_bg: require('@/assets/img/homeNav/home-bg2.png'),
        homeNav_date_bg: require('@/assets/img/homeNav/date-bg2.png'),
        homeNav_date_day_color: '#1571e9',
        homeNav_date_date_color: '#455467',
        homeNav_admin_bg: require('@/assets/img/homeNav/admin-bg2.png'),
        homeNav_admin_bgcolor: '#f0f7ff',//
        homeNav_ad_bg: require('@/assets/img/homeNav/ad-bg2.png'),
        homeNav_content_bg: require('@/assets/img/homeNav/content-center-bg2.png'),
        spaceTime_mapStyle: '4c75aaeedeb80a9ea4981638065771c0',
        tooltip: {///echarts提示框样式
            backgroundColor: "rgba(31,44,66,0.9)", //设置背景图片 rgba格式
            borderColor: 'transparent',
            textStyle: {
                color: '#92add7'
            }
        }
    }
}
/*
 * @Author: herro
 * @Date: 2022-08-22 14:08:16
 * @Description: vuex
 */
import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import login from './modules/login'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    app, login
  }
})

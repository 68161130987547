import vuescroll from 'vuescroll'
import { service } from '../http/service'
// import { axiosInstance } from './axios-config'

// import AnchorNavigation from './AnchorNavigation'
// import BlueHeadTable from './BlueHeadTable'
// import BaseTable from './BaseTable'
// import Container from './Container'
// import CornerInfo from './CornerInfo'
// import Floor from './Floor'
// import MainTitle from './MainTitle'
// import Module from './Module'
// import ModuleTitle from './ModuleTitle'
// import MultipleMenu from './MultipleMenu'
// import IpiProgress from './IpiProgress'
// import RadioMenu from './RadioMenu'
// import SelectTable from './SelectTable'
// import SlashTitle from './SlashTitle'
// import SwitchGroup from './SwitchGroup'

import renderChart from './render-chart'
import unitConversion from './unit-conversion'

const IPlugin = {}
IPlugin.install = function(Vue /* , options */) {
  Vue.use(vuescroll)

  Vue.prototype.$chart = renderChart
  Vue.prototype.$http = service;
  Vue.prototype.$convert = unitConversion

  Vue.prototype.$scrollConfig = {
    vuescroll: { detectResize: true, mode: 'native', sizeStrategy: 'percent' },
    scrollPanel: { scrollingX: true, scrollingY: true },
    bar: { background: 'rgba(255, 255, 255, 0.2)' },
  }

  // Vue.component(AnchorNavigation.name, AnchorNavigation)
  // Vue.component(BlueHeadTable.name, BlueHeadTable)
  // Vue.component(BaseTable.name, BaseTable)
  // Vue.component(Container.name, Container)
  // Vue.component(CornerInfo.name, CornerInfo)
  // Vue.component(Floor.name, Floor)
  // Vue.component(MainTitle.name, MainTitle)
  // Vue.component(Module.name, Module)
  // Vue.component(ModuleTitle.name, ModuleTitle)
  // Vue.component(MultipleMenu.name, MultipleMenu)
  // Vue.component(IpiProgress.name, IpiProgress)
  // Vue.component(RadioMenu.name, RadioMenu)
  // Vue.component(SelectTable.name, SelectTable)
  // Vue.component(SlashTitle.name, SlashTitle)
  // Vue.component(SwitchGroup.name, SwitchGroup)
}

export default IPlugin

/*
 * @Author: herro
 * @Date: 2022-08-25 09:41:46
 * @Description: 
 */
import theme from "@/theme";

export default {
    install(Vue) {
        Vue.mixin({
            created() {
                // console.log('全局混入的钩子函数');
            },
            computed: {
                //主题对象
                THEME() {
                    return theme[this.$store.state.app.theme]
                },
                uploadHead() {
                    return 'http://127.0.0.1:8080/technological/sys/common/static/'
                },
                TOKEN() {
                    return sessionStorage.getItem('TOKEN')
                },
                BASE_URL() {
                    return process.env.VUE_APP_BASE_URL;
                }
            }
        })
    }
}

export default {
    state: {
        theme: 'dark',
        geoBoundary: '',//地图自定义区域经纬度
        radius:null,//半径
    },
    mutations: {
        CHANGE_THEME(state, val) {
            localStorage.setItem('theme', val)
            state.theme = val
        },
        SET_AUTO_GEO(state, val) {
            state.geoBoundary = val
        },
        SET_AUTO_RADIUS(state, val) {
            state.radius = val
        }
    }
}
<!--
 * @Author: name
 * @Date: 2022-08-22 14:08:16
 * @Description: 
-->
<template>
  <div id="app">
    <!-- <keep-alive> -->
      <!-- <transition :name="transitionName"> -->
      <router-view />
      <!-- </transition> -->
    <!-- </keep-alive> -->
  </div>
</template>

<script>
export default {
  name: "app",
  data() {
    return {
      transitionName: "view-in",
    };
  },
  watch: {
    $route(to, from) {
      const routeDeep = [
        "index",
        "rules",
        "dividequery",
        "weekentry",
        "prepareentry",
        "answer",
        "success",
      ];
      const toDepth = routeDeep.indexOf(to.name);
      const fromDepth = routeDeep.indexOf(from.name);
      this.transitionName = toDepth > fromDepth ? "view-in" : "view-out";
    },
  },
};
</script>

<style lang="less">
@import "./assets/less";
#app {
  height: 100vh;
  // width: 100vw;
}

/deep/ .view-in-enter-active,
/deep/ .view-in-leave-active,
/deep/ .view-out-enter-active,
/deep/ .view-out-leave-active {
  will-change: transform;
  transition: all 500ms ease;
  height: 100%;
  top: 0;
  position: absolute;
  width: 100%;
  backface-visibility: hidden;
  perspective: 1000;
}

/deep/ .view-out-enter {
  transform: translate3d(-100%, 0, 0);
}

/deep/ .view-out-leave-active {
  transform: translate3d(100%, 0, 0);
}

/deep/ .view-in-enter {
  transform: translate3d(100%, 0, 0);
}

/deep/ .view-in-leave-active {
  transform: translate3d(-100%, 0, 0);
}
</style>


import Home from '../views/homeNav'
export default [
	{
		meta: {
			title: '导航页'
		},
		path: '/home',
		name: 'home',
		component: Home
	},
	{
		path: '/',
		redirect: '/home'
	},
	{
		meta: {
			title: '登录'
		},
		path: '/login',
		name: 'login',
		component: () => import('../views/login')
	},
	{
		meta: {
			title: '登录'
		},
		path: '/companyLogin',
		name: 'companyLogin',
		component: () => import('../views/companyLogin')
	},
	{
		path: '/platform_show',
		redirect: '/platform_show/survey',
		name: 'platform_show',
		component: () => import('../views/platform_show'),
		children: [
			{
				meta: {
					title: '高新概况'
				},
				path: '/platform_show/survey',
				name: 'survey',
				component: () => import('../views/survey')
			},
			{
				meta: {
					title: '科创企业'
				},
				path: '/platform_show/enterprises',
				name: 'enterprises',
				component: () => import('../views/enterprises')
			},
			{
				meta: {
					title: '科创载体'
				},
				path: '/platform_show/carrier',
				name: 'carrier',
				component: () => import('../views/carrier')
			},
			{
				meta: {
					title: '科技成果转化'
				},
				path: '/platform_show/achievements',
				name: 'achievements',
				component: () => import('../views/achievements')
			},
			{
				meta: {
					title: '创新要素'
				},
				path: '/platform_show/factor',
				name: 'factor',
				component: () => import('../views/factor')
			},
			{
				meta: {
					title: '科创项目'
				},
				path: '/platform_show/project',
				name: 'project',
				component: () => import('../views/project')
			},
		]
	},
	{
		meta: {
			title: '科创时空平台'
		},
		path: '/platform_spaceTime',
		name: 'platform_spaceTime',
		component: () => import('../views/platform_spaceTime')
	},
	{
		meta: {
			title: '科创工作平台'
		},
		path: '/platform_work',
		name: 'platform_work',
		component: () => import('../views/platform_work')
	},
	{
		meta: {
			title: '科创分析平台'
		},
		path: '/platform_analysis',
		name: 'platform_analysis',
		redirect: '/platform_analysis/index_analysis',
		component: () => import('../views/platform_analysis'),
		children: [
			{
				meta: {
					title: '选指标综合分析'
				},
				path: '/platform_analysis/index_analysis',
				name: 'index_analysis',
				component: () => import('../views/index_analysis')
			},
		]
	},
	{
		meta: {
			title: '科创要素平台'
		},
		path: '/platform_factor',
		name: 'platform_factor',
		redirect: '/platform_factor/policy',
		component: () => import('../views/platform_factor'),
		children: [
			{
				meta: {
					title: '科创政策'
				},
				path: '/platform_factor/policy',
				name: 'policy',
				redirect: '/platform_factor/policy/analysis',
				component: () => import('../views/policy'),
				children: [
					{
						meta: {
							title: '政策分析'
						},
						path: 'analysis',
						name: 'policy_analysis',
						component: () => import('../views/policy/analysis.vue')
					},
					{
						meta: {
							title: '政策管理'
						},
						path: 'manage',
						name: 'policy_manage',
						component: () => import('../views/policy/manage.vue')
					},
					{
						meta: {
							title: '政策评估'
						},
						path: 'assessment',
						name: 'policy_assessment',
						component: () => import('../views/policy/assessment.vue')
					},
					{
						meta: {
							title: '政策详情'
						},
						path: 'analysis_detail',
						name: 'analysis_detail',
						component: () => import('../views/policy/analysis_detail.vue')
					},
					{
						meta: {
							title: '政策分析详情'
						},
						path: 'policy_analysis_detail',
						name: 'policy_analysis_detail',
						component: () => import('../views/policy/policy_analysis_detail/index.vue')
					},
					{
						meta: {
							title: '政策内容详情'
						},
						path: 'policy_detail',
						name: 'policy_detail',
						component: () => import('../views/policy/policy_detail.vue')
					},
					{
						meta: {
							title: '添加政策'
						},
						path: 'add_policy',
						name: 'add_policy',
						component: () => import('../views/policy/addPolicy/index.vue')
					},
					{
						meta: {
							title: '申报企业'
						},
						path: 'declareCompany',
						name: 'declareCompany',
						component: () => import('../views/policy/declareCompany.vue')
					},
					{
						meta: {
							title: '添加申报企业'
						},
						path: 'addDeclareCompany',
						name: 'addDeclareCompany',
						component: () => import('../views/policy/addDeclareCompany.vue')
					},
				]
			},
			{
				meta: {
					title: '科技成果'
				},
				path: '/platform_factor/achievements',
				name: 'factor_achievements',
				component: () => import('../views/platform_factor/achievements'),
			},
			{
				meta: {
					title: '科创金融'
				},
				path: '/platform_factor/finance',
				name: 'finance',
				component: () => import('../views/platform_factor/finance'),
			},
			{
				meta: {
					title: '创建模型'
				},
				path: '/platform_factor/createModel',
				name: 'createModel',
				component: () => import('../views/platform_factor/finance/createModel'),
			},
			{
				meta: {
					title: '匹配企业'
				},
				path: '/platform_factor/matchEnterprise',
				name: 'matchEnterprise',
				component: () => import('../views/platform_factor/finance/matchEnterprise'),
			},
			{
				meta: {
					title: '中介机构'
				},
				path: '/platform_factor/achievements/agency',
				name: 'agency',
				component: () => import('../views/platform_factor/achievements/agency'),
			},
			{
				meta: {
					title: '中介机构详情'
				},
				path: '/platform_factor/achievements/agency/detail',
				name: 'agency_detail',
				component: () => import('../views/platform_factor/achievements/agency/components/c_detail.vue'),
			},
			{
				meta: {
					title: '体制机制'
				},
				path: '/platform_factor/mechanism',
				name: 'mechanism',
				component: () => import('../views/mechanism'),
			},
			{
				meta: {
					title: '技术经理人团队数量统计'
				},
				path: '/platform_factor/mechanism/manager_detail',
				name: 'manager_detail',
				component: () => import('../views/mechanism/manager'),
			},
			{
				meta: {
					title: '技术经理人团队考核'
				},
				path: '/platform_factor/mechanism/program_check',
				name: 'program_check',
				component: () => import('../views/mechanism/check'),
			},
			{
				meta: {
					title: '团队考核'
				},
				path: '/platform_factor/mechanism/checkDetail',
				name: 'checkDetail',
				component: () => import('../views/mechanism/check/checkDetail.vue'),
			},
			{
				meta: {
					title: '添加技术经理人团队'
				},
				path: '/platform_factor/mechanism/addManager',
				name: 'addManager',
				component: () => import('../views/mechanism/addManager'),
			},
		]
	},
	{
		meta: {
			title: '一院一所一校'
		},
		path: '/platform_show/yuansuoxiao',
		name: 'yuansuoxiao',
		component: () => import('../views/yuansuoxiao')
	},
	{
		meta: {
			title: '科创主体平台'
		},
		path: '/platform_main',
		name: 'platform_main',
		redirect: '/platform_main/enterprise',
		component: () => import('../views/platform_main'),
		children: [

			{
				meta: {
					title: '科创企业'
				},
				path: 'enterprise',
				name: 'enterprise',
				redirect: '/platform_main/enterprise/survey_index',
				component: () => import('../views/platform_main/enterprise'),
				children: [
					{
						meta: {
							title: '企业概况指标'
						},
						path: '/platform_main/enterprise/survey_index',
						name: 'survey_index',
						component: () => import('../views/platform_main/enterprise/survey_index')
					},
					{
						meta: {
							title: '产业链分布情况'
						},
						path: '/platform_main/enterprise/industrialChain',
						name: 'industrialChain',
						component: () => import('../views/platform_main/enterprise/industrialChain')
					},
					{
						meta: {
							title: '企业创新指标'
						},
						path: '/platform_main/enterprise/innovate_index',
						name: 'innovate_index',
						redirect: '/platform_main/enterprise/innovate_index/entrepreneurship',
						component: () => import('../components/C_parent'),
						children: [
							{
								meta: {
									title: '创新能力和创业活跃度'
								},
								path: '/platform_main/enterprise/innovate_index/entrepreneurship',
								name: 'entrepreneurship',
								component: () => import('../views/platform_main/enterprise/innovate_index/entrepreneurship.vue')
							},
							{
								meta: {
									title: '结构优化和产业价值链'
								},
								path: '/platform_main/enterprise/innovate_index/industrialValue',
								name: 'industrialValue',
								component: () => import('../views/platform_main/enterprise/innovate_index/industrialValue.vue')
							},
							{
								meta: {
									title: '绿色发展和宜居包容性'
								},
								path: '/platform_main/enterprise/innovate_index/greenDev',
								name: 'greenDev',
								component: () => import('../views/platform_main/enterprise/innovate_index/greenDev.vue')
							},
							{
								meta: {
									title: '开放创新和国际竞争力'
								},
								path: '/platform_main/enterprise/innovate_index/innovateOpen',
								name: 'innovateOpen',
								component: () => import('../views/platform_main/enterprise/innovate_index/innovateOpen.vue')
							},
							{
								meta: {
									title: '综合质效和持续创新力'
								},
								path: '/platform_main/enterprise/innovate_index/innovationLast',
								name: 'innovationLast',
								component: () => import('../views/platform_main/enterprise/innovate_index/innovationLast.vue')
							},
						]
					}, {
						meta: {
							title: '企业财务指标'
						},
						path: '/platform_main/enterprise/finance_index',
						name: 'finance_index',
						redirect: '/platform_main/enterprise/finance_index/tax_index',
						component: () => import('../components/C_parent'),
						children: [
							{
								meta: {
									title: '产值营收类指标'
								},
								path: '/platform_main/enterprise/finance_index/output_index',
								name: 'output_index',
								component: () => import('../views/platform_main/enterprise/finance_index/output_index.vue')
							},
							{
								meta: {
									title: '成本费用类指标'
								},
								path: '/platform_main/enterprise/finance_index/cost_index',
								name: 'cost_index',
								component: () => import('../views/platform_main/enterprise/finance_index/cost_index.vue')
							},
							{
								meta: {
									title: '利润/产值/负债类指标'
								},
								path: '/platform_main/enterprise/finance_index/profit_index',
								name: 'profit_index',
								component: () => import('../views/platform_main/enterprise/finance_index/profit_index.vue')
							},
							{
								meta: {
									title: '税务类指标'
								},
								path: '/platform_main/enterprise/finance_index/tax_index',
								name: 'tax_index',
								component: () => import('../views/platform_main/enterprise/finance_index/tax_index.vue')
							},
						]
					}, {
						meta: {
							title: '企业其他指标'
						},
						path: '/platform_main/enterprise/other_index',
						name: 'other_index',
						redirect: '/platform_main/enterprise/other_index/tax_index',
						component: () => import('../components/C_parent'),
						children: [
							{
								meta: {
									title: '用人指标'
								},
								path: '/platform_main/enterprise/finance_index/employment_index',
								name: 'employment_index',
								component: () => import('../views/platform_main/enterprise/other_index/employment_index.vue')
							},
							{
								meta: {
									title: '其他指标'
								},
								path: '/platform_main/enterprise/finance_index/otherIndex',
								name: 'otherIndex',
								component: () => import('../views/platform_main/enterprise/other_index/otherIndex.vue')
							},
						]
					},

					{
						meta: {
							title: '企业比较'
						},
						path: '/platform_main/enterprise/enterprise_comparison',
						name: 'enterprise_comparison',
						component: () => import('../views/platform_main/enterprise/enterprise_comparison')
					},
				]
			},
			{
				meta: {
					title: '企业上市'
				},
				path: 'Listing',
				name: 'Listing',
				redirect: '/platform_main/Listing/survey_index',
				component: () => import('../views/platform_main/enterprise'),
				children: [
					{
						meta: {
							title: '企业概况指标'
						},
						path: '/platform_main/Listing/survey_index',
						name: 'Listing_survey_index',
						component: () => import('../views/platform_main/enterprise/survey_index')
					},
					{
						meta: {
							title: '产业链分布情况'
						},
						path: '/platform_main/Listing/industrialChain',
						name: 'Listing_industrialChain',
						component: () => import('../views/platform_main/enterprise/industrialChain')
					},
					{
						meta: {
							title: '企业创新指标'
						},
						path: '/platform_main/Listing/innovate_index',
						name: 'Listing_innovate_index',
						redirect: '/platform_main/Listing/innovate_index/entrepreneurship',
						component: () => import('../components/C_parent'),
						children: [
							{
								meta: {
									title: '创新能力和创业活跃度'
								},
								path: '/platform_main/Listing/innovate_index/entrepreneurship',
								name: 'Listing_entrepreneurship',
								component: () => import('../views/platform_main/enterprise/innovate_index/entrepreneurship.vue')
							},
							{
								meta: {
									title: '结构优化和产业价值链'
								},
								path: '/platform_main/Listing/innovate_index/industrialValue',
								name: 'Listing_industrialValue',
								component: () => import('../views/platform_main/enterprise/innovate_index/industrialValue.vue')
							},
							{
								meta: {
									title: '绿色发展和宜居包容性'
								},
								path: '/platform_main/Listing/innovate_index/greenDev',
								name: 'Listing_greenDev',
								component: () => import('../views/platform_main/enterprise/innovate_index/greenDev.vue')
							},
							{
								meta: {
									title: '开放创新和国际竞争力'
								},
								path: '/platform_main/Listing/innovate_index/innovateOpen',
								name: 'Listing_innovateOpen',
								component: () => import('../views/platform_main/enterprise/innovate_index/innovateOpen.vue')
							},
							{
								meta: {
									title: '综合质效和持续创新力'
								},
								path: '/platform_main/Listing/innovate_index/innovationLast',
								name: 'Listing_innovationLast',
								component: () => import('../views/platform_main/enterprise/innovate_index/innovationLast.vue')
							},
						]
					}, {
						meta: {
							title: '企业财务指标'
						},
						path: '/platform_main/Listing/finance_index',
						name: 'Listing_finance_index',
						redirect: '/platform_main/Listing/finance_index/tax_index',
						component: () => import('../components/C_parent'),
						children: [
							{
								meta: {
									title: '产值营收类指标'
								},
								path: '/platform_main/Listing/finance_index/output_index',
								name: 'Listing_output_index',
								component: () => import('../views/platform_main/enterprise/finance_index/output_index.vue')
							},
							{
								meta: {
									title: '成本费用类指标'
								},
								path: '/platform_main/Listing/finance_index/cost_index',
								name: 'Listing_cost_index',
								component: () => import('../views/platform_main/enterprise/finance_index/cost_index.vue')
							},
							{
								meta: {
									title: '利润/产值/负债类指标'
								},
								path: '/platform_main/Listing/finance_index/profit_index',
								name: 'Listing_profit_index',
								component: () => import('../views/platform_main/enterprise/finance_index/profit_index.vue')
							},
							{
								meta: {
									title: '税务类指标'
								},
								path: '/platform_main/Listing/finance_index/tax_index',
								name: 'Listing_tax_index',
								component: () => import('../views/platform_main/enterprise/finance_index/tax_index.vue')
							},
						]
					}, {
						meta: {
							title: '企业其他指标'
						},
						path: '/platform_main/Listing/other_index',
						name: 'Listing_other_index',
						redirect: '/platform_main/Listing/other_index/tax_index',
						component: () => import('../components/C_parent'),
						children: [
							{
								meta: {
									title: '用人指标'
								},
								path: '/platform_main/Listing/finance_index/employment_index',
								name: 'Listing_employment_index',
								component: () => import('../views/platform_main/enterprise/other_index/employment_index.vue')
							},
							{
								meta: {
									title: '其他指标'
								},
								path: '/platform_main/Listing/finance_index/otherIndex',
								name: 'Listing_otherIndex',
								component: () => import('../views/platform_main/enterprise/other_index/otherIndex.vue')
							},
						]
					},

					{
						meta: {
							title: '企业比较'
						},
						path: '/platform_main/Listing/enterprise_comparison',
						name: 'Listing_enterprise_comparison',
						component: () => import('../views/platform_main/enterprise/enterprise_comparison')
					},
				]
			},
			{
				meta: {
					title: '科创载体'
				},
				path: 'carrier',
				name: 'carrier',
				redirect: '/platform_main/carrier/survey_carrier',
				component: () => import('../views/platform_main/carrier'),
				children: [
					{
						meta: {
							title: '载体概况指标'
						},
						path: '/platform_main/carrier/survey_carrier',
						name: 'survey_carrier',
						component: () => import('../views/platform_main/carrier/survey_carrier')
					},
					{
						meta: {
							title: '孵化器指标'
						},
						path: '/platform_main/carrier/Incubator_index',
						name: 'Incubator_index',
						component: () => import('../components/C_parent'),
						redirect: '/platform_main/carrier/Incubator_index/service',
						children: [
							{
								meta: {
									title: '服务能力'
								},
								path: '/platform_main/carrier/Incubator_index/service',
								name: 'service',
								component: () => import('../views/platform_main/carrier/Incubator_index/service.vue'),
							},
							{
								meta: {
									title: '孵化绩效'
								},
								path: '/platform_main/carrier/Incubator_index/achievements',
								name: 'achievements',
								component: () => import('../views/platform_main/carrier/Incubator_index/achievements.vue'),
							},
							{
								meta: {
									title: '社会贡献'
								},
								path: '/platform_main/carrier/Incubator_index/contribution',
								name: 'contribution',
								component: () => import('../views/platform_main/carrier/Incubator_index/contribution.vue'),
							}
						]
					},
					{
						meta: {
							title: '工程中心指标'
						},
						path: '/platform_main/carrier/enginerCenter',
						name: 'enginerCenter',
						component: () => import('../components/C_parent'),
						redirect: '/platform_main/carrier/enginerCenter/strategy',
						children: [
							{
								meta: {
									title: '服务国家战略'
								},
								path: '/platform_main/carrier/enginerCenter/strategy',
								name: 'strategy',
								component: () => import('../components/C_parent'),
								redirect: '/platform_main/carrier/enginerCenter/strategy/tasks',
								children: [
									{
										meta: {
											title: '承担任务'
										},
										path: '/platform_main/carrier/enginerCenter/strategy/tasks',
										name: 'tasks',
										component: () => import('../views/platform_main/carrier/enginerCenter/tasks.vue'),
									},
								]
							},
							{
								meta: {
									title: '推动产业发展'
								},
								path: '/platform_main/carrier/enginerCenter/industrialDevelopment',
								name: 'industrialDevelopment',
								component: () => import('../components/C_parent'),
								redirect: '/platform_main/carrier/enginerCenter/industrialDevelopment/RDachievements',
								children: [
									{
										meta: {
											title: '研发成果'
										},
										path: '/platform_main/carrier/enginerCenter/industrialDevelopment/RDachievements',
										name: 'RDachievements',
										component: () => import('../views/platform_main/carrier/enginerCenter/RDachievements.vue'),
									},
									{
										meta: {
											title: '成果转化'
										},
										path: '/platform_main/carrier/enginerCenter/industrialDevelopment/achievementsTransformation',
										name: 'achievementsTransformation',
										component: () => import('../views/platform_main/carrier/enginerCenter/achievementsTransformation.vue'),
									},
								]
							},
							{
								meta: {
									title: '强化自身建设'
								},
								path: '/platform_main/carrier/enginerCenter/strengthenSelf',
								name: 'strengthenSelf',
								component: () => import('../components/C_parent'),
								redirect: '/platform_main/carrier/enginerCenter/strengthenSelf/RDinvestment',
								children: [
									{
										meta: {
											title: '研发投入'
										},
										path: '/platform_main/carrier/enginerCenter/industrialDevelopment/RDinvestment',
										name: 'RDinvestment',
										component: () => import('../views/platform_main/carrier/enginerCenter/RDinvestment.vue'),
									},
									{
										meta: {
											title: '人才培养'
										},
										path: '/platform_main/carrier/enginerCenter/industrialDevelopment/personnel',
										name: 'personnel',
										component: () => import('../views/platform_main/carrier/enginerCenter/personnel.vue'),
									},
									{
										meta: {
											title: '平台支撑'
										},
										path: '/platform_main/carrier/enginerCenter/industrialDevelopment/platformSupport',
										name: 'platformSupport',
										component: () => import('../views/platform_main/carrier/enginerCenter/platformSupport.vue'),
									},
								]
							},
						]
					},
					{
						meta: {
							title: '企业技术中心指标'
						},
						path: '/platform_main/carrier/technologyCenter',
						name: 'technologyCenter',
						component: () => import('../components/C_parent'),
						redirect: '/platform_main/carrier/technologyCenter/STinvestment',
						children: [
							{
								meta: {
									title: '科技投入机制'
								},
								path: '/platform_main/carrier/technologyCenter/STinvestment',
								name: 'STinvestment',
								component: () => import('../views/platform_main/carrier/technologyCenter/STinvestment.vue'),
							},
							{
								meta: {
									title: '人才激励机制'
								},
								path: '/platform_main/carrier/technologyCenter/talentIncentive',
								name: 'talentIncentive',
								component: () => import('../views/platform_main/carrier/technologyCenter/talentIncentive.vue'),
							},
							{
								meta: {
									title: '科技人才培养'
								},
								path: '/platform_main/carrier/technologyCenter/STtalents',
								name: 'STtalents',
								component: () => import('../views/platform_main/carrier/technologyCenter/STtalents.vue'),
							},
							{
								meta: {
									title: '外部资源利用'
								},
								path: '/platform_main/carrier/technologyCenter/resourcesUse',
								name: 'resourcesUse',
								component: () => import('../views/platform_main/carrier/technologyCenter/resourcesUse.vue'),
							},
							{
								meta: {
									title: '创新队伍建设'
								},
								path: '/platform_main/carrier/technologyCenter/innovationTeam',
								name: 'innovationTeam',
								component: () => import('../views/platform_main/carrier/technologyCenter/innovationTeam.vue'),
							},
							{
								meta: {
									title: '创新能力建设'
								},
								path: '/platform_main/carrier/technologyCenter/innovativeConstruction',
								name: 'innovativeConstruction',
								component: () => import('../views/platform_main/carrier/technologyCenter/innovativeConstruction.vue'),
							},
							{
								meta: {
									title: '技术创新产出'
								},
								path: '/platform_main/carrier/technologyCenter/innovationOutput',
								name: 'innovationOutput',
								component: () => import('../views/platform_main/carrier/technologyCenter/innovationOutput.vue'),
							},
							{
								meta: {
									title: '技术创新效益'
								},
								path: '/platform_main/carrier/technologyCenter/innovationBenefits',
								name: 'innovationBenefits',
								component: () => import('../views/platform_main/carrier/technologyCenter/innovationBenefits.vue'),
							}
						]
					}
					// {
					// 	meta: {
					// 		title: '项目分布&投资'
					// 	},
					// 	path: '/platform_main/program/distribution',
					// 	name: 'distribution',
					// 	component: () => import('../views/platform_main/program/distribution')
					// },
					// {
					// 	meta: {
					// 		title: '项目详情'
					// 	},
					// 	path: '/platform_main/program/programDetail',
					// 	name: 'programDetail',
					// 	component: () => import('../views/platform_main/program/program_detail')
					// },
				]
			},
			{
				meta: {
					title: '科创项目'
				},
				path: 'program',
				name: 'program',
				redirect: '/platform_main/program/survey_program',
				component: () => import('../views/platform_main/program'),
				children: [
					{
						meta: {
							title: '项目概况'
						},
						path: '/platform_main/program/survey_program',
						name: 'survey_program',
						component: () => import('../views/platform_main/program/survey_program')
					},
					{
						meta: {
							title: '项目分布&投资'
						},
						path: '/platform_main/program/distribution',
						name: 'distribution',
						component: () => import('../views/platform_main/program/distribution')
					},
					{
						meta: {
							title: '项目详情'
						},
						path: '/platform_main/program/programDetail',
						name: 'programDetail',
						component: () => import('../views/platform_main/program/program_detail')
					},
				]
			},
			{
				meta: {
					title: '添加企业'
				},
				path: 'addEnterprise',
				name: 'addEnterprise',
				component: () => import('../views/platform_main/enterprise/survey_index/addEnterprise/index.vue'),
			},
			{
				meta: {
					title: '添加项目'
				},
				path: 'addProgram',
				name: 'addProgram',
				component: () => import('../views/platform_main/program/survey_program/addProgram'),
			},
			{
				meta: {
					title: '添加企业指标'
				},
				path: 'addIndex',
				name: 'addIndex',
				component: () => import('../views/platform_main/enterprise/innovate_index/addIndex'),
			},
			{
				meta: {
					title: '添加载体指标'
				},
				path: 'addCarrierIndex',
				name: 'addCarrierIndex',
				component: () => import('../views/platform_main/carrier/survey_carrier/addCarrierIndex'),
			},
			{
				meta: {
					title: '企业详情'
				},
				path: 'enterprise_detail',
				name: 'enterprise_detail',
				component: () => import('../views/platform_main/enterprise/enterprise_detail'),
			},
			{
				meta: {
					title: '项目详情'
				},
				path: 'program_detail',
				name: 'program_detail',
				component: () => import('../views/platform_main/program/survey_program/programDetail'),
			},
			{
				meta: {
					title: '载体详情'
				},
				path: 'carrier_detail',
				name: 'carrier_detail',
				component: () => import('../views/platform_main/carrier/survey_carrier/programDetail'),
			},
		]
	},
	{
		meta: {
			title: '重点科技型企业服务平台'
		},
		path: '/platform_company',
		name: 'platform_company',
		redirect: '/platform_company/companyData',
		component: () => import('../views/platform_company'),
		children: [
			{
				meta: {
					title: '企业数据'
				},
				path: '/platform_company/companyData',
				name: 'companyData',
				component: () => import('../views/platform_company/companyData')
			},
			{
				meta: {
					title: '高级检索'
				},
				path: '/platform_company/advancedSearch',
				name: 'advancedSearch',
				component: () => import('../views/platform_company/advancedSearch')
			},
		]
	},
	{
		meta: {
			title: '新增企业数据'
		},
		path: '/platform_company/addCompany',
		name: 'addCompany',
		component: () => import('../views/platform_company/addCompany')
	},
	{
		meta: {
			title: '企业数据详情'
		},
		path: '/platform_company/companyDetail',
		name: 'companyDetail',
		component: () => import('../views/platform_company/companyDetail')
	},
	{
		meta: {
			title: '新增高级检索'
		},
		path: '/platform_company/addCondition',
		name: 'addCondition',
		component: () => import('../views/platform_company/advancedSearch/addCondition')
	},
	{
		meta: {
			title: '匹配企业'
		},
		path: '/platform_company/matchCompany',
		name: 'matchCompany',
		component: () => import('../views/platform_company/advancedSearch/matchCompany')
	},
	{
		meta: {
			title: '绿色发展高质量研究院'
		},
		path: '/greenHighQuality',
		name: 'greenHighQuality',
		redirect: '/greenHighQuality/index',
		component: () => import('../components/C_parent'),
		children: [
			{
				meta: {
					title: '绿色发展高质量研究院'
				},
				path: '/greenHighQuality/index',
				name: 'greenHighQualityIndex',
				component: () => import('../views/greenHighQuality/index')
			},
			{
				meta: {
					title: '政策详情'
				},
				path: '/greenHighQuality/policyDetail/:id',
				name: 'policyDetail',
				component: () => import('../views/greenHighQuality/policyDetail')
			},
		]
	},
]